import React from 'react';
import { MinecraftColors } from '../utils/constants';
import '../styles/ColorSelection.css';

const ColorSelection = ({ style, selectedColors, setSelectedColors, onColorChange }) => {
    return (
        <div className="color-select-container">
            <div className="color-select-group">
                <label className="color-select-label">
                    {style === 'hypixel'
                        ? 'Primary Color (Default: Gold)'
                        : 'Primary Color (Default: Red)'}
                </label>
                <select
                    className="color-select"
                    value={selectedColors.color1}
                    onChange={(e) => {
                        setSelectedColors({...selectedColors, color1: e.target.value});
                        onColorChange();
                    }}
                >
                    {Object.entries(MinecraftColors).map(([name, value]) => (
                        <option key={value} value={value}>{name}</option>
                    ))}
                </select>
            </div>

            <div className="color-select-group">
                <label className="color-select-label">
                    {style === 'hypixel'
                        ? 'Secondary Color (Default: Yellow)'
                        : 'Secondary Color (Default: Dark Red)'}
                </label>
                <select
                    className="color-select"
                    value={selectedColors.color2}
                    onChange={(e) => {
                        setSelectedColors({...selectedColors, color2: e.target.value});
                        onColorChange();
                    }}
                >
                    {Object.entries(MinecraftColors).map(([name, value]) => (
                        <option key={value} value={value}>{name}</option>
                    ))}
                </select>
            </div>

            <div className="color-select-group">
                <label className="color-select-label">
                    {style === 'hypixel'
                        ? 'Transition Color (Default: White)'
                        : 'Highlight Color (Default: White)'}
                </label>
                <select
                    className="color-select"
                    value={selectedColors.color3}
                    onChange={(e) => {
                        setSelectedColors({...selectedColors, color3: e.target.value});
                        onColorChange();
                    }}
                >
                    {Object.entries(MinecraftColors).map(([name, value]) => (
                        <option key={value} value={value}>{name}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default ColorSelection;