export const defaultGhostlyColors = {
    color1: '&c', // RED
    color2: '&4', // DARK_RED
    color3: '&f'  // WHITE
};

export const generateGhostlyStyle = (word, selectedColors, formatCodes) => {
    const baseSpaces = "      ";
    const midSpaces = "     ";
    const shortSpaces = "   ";
    const singleSpace = " ";
    let result = [];

    // İlk üç kare
    result.push(`${selectedColors.color1}${formatCodes}${word}`);
    result.push(`${selectedColors.color1}${formatCodes}${word}`);
    result.push(`${selectedColors.color1}${formatCodes}${word}`);

    // İlk harf animasyonu
    result.push(`${selectedColors.color1}${formatCodes}${baseSpaces}${selectedColors.color2}${formatCodes}${word[0]}`);
    result.push(`${selectedColors.color1}${formatCodes}${midSpaces}${selectedColors.color2}${formatCodes}${word[0]} `);
    result.push(`${selectedColors.color1}${formatCodes}${shortSpaces}${selectedColors.color2}${formatCodes}${word[0]}${shortSpaces}`);
    result.push(`${selectedColors.color1}${formatCodes}${singleSpace}${selectedColors.color2}${formatCodes}${word[0]}${midSpaces}`);

    // Her harf için animasyon
    for(let i = 0; i < word.length-1; i++) {
        let currentPart = word.substring(0, i + 1);
        let nextLetter = word[i + 1];

        result.push(`${selectedColors.color1}${formatCodes}${currentPart}${midSpaces}${selectedColors.color2}${formatCodes}${nextLetter}`);
        result.push(`${selectedColors.color1}${formatCodes}${currentPart}${shortSpaces}${selectedColors.color2}${formatCodes}${nextLetter}  `);
        result.push(`${selectedColors.color1}${formatCodes}${currentPart}${selectedColors.color2}${formatCodes}${nextLetter}    `);
    }

    // Renk geçişleri
    result.push(`${selectedColors.color2}${formatCodes}${word}`);
    result.push(`${selectedColors.color3}${formatCodes}${word}`);
    result.push(`${selectedColors.color2}${formatCodes}${word}`);
    result.push(`${selectedColors.color3}${formatCodes}${word}`);
    result.push(`${selectedColors.color1}${formatCodes}${word}`);

    return result;
};