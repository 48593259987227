import React, { useState } from 'react';
import ColorSelection from './components/ColorSelection';
import { MinecraftFormatting } from './utils/constants';
import { generateGhostlyStyle, defaultGhostlyColors } from './utils/styles/ghostlyStyle';
import { generateHypixelStyle, defaultHypixelColors } from './utils/styles/hypixelStyle';
import './styles/Animation.css';

const AnimationGeneratorStyled = () => {
    const [currentText, setCurrentText] = useState('');
    const [generatedText, setGeneratedText] = useState('');
    const [selectedColors, setSelectedColors] = useState(defaultGhostlyColors);
    const [style, setStyle] = useState('ghostly');
    const [formatting, setFormatting] = useState(['BOLD']);

    const getFormattingCodes = () => {
        return formatting.map(format => MinecraftFormatting[format]).join('');
    };

    const handleGenerate = () => {
        if (!currentText) return;

        const processedText = currentText.toUpperCase();
        const formatCodes = getFormattingCodes();

        let frames = [];
        if (style === 'hypixel') {
            frames = generateHypixelStyle(processedText, selectedColors, formatCodes);
        } else {
            frames = generateGhostlyStyle(processedText, selectedColors, formatCodes);
        }

        setGeneratedText(frames.map(frame => `    - "${frame}"`).join('\n'));
    };

    const handleStyleChange = (newStyle) => {
        setStyle(newStyle);
        setSelectedColors(newStyle === 'hypixel' ? defaultHypixelColors : defaultGhostlyColors);
        setTimeout(handleGenerate, 0);
    };

    const handleFormatting = (format) => {
        setFormatting(prev =>
            prev.includes(format)
                ? prev.filter(f => f !== format)
                : [...prev, format]
        );

        if (currentText) {
            setTimeout(handleGenerate, 0);
        }
    };

    return (
        <div className="animation-container">
            <div className="content-wrapper">
                <h1 className="title">
                    MTP | Scoreboard Animation Generator
                </h1>

                <div className="grid-container">
                    {/* Preview Section */}
                    <div className="card">
                        <h2 className="card-title">Animation Preview</h2>
                        <div className="preview-container">
                            <div className="input-group">
                                <input
                                    value={currentText}
                                    onChange={(e) => setCurrentText(e.target.value)}
                                    placeholder="Enter your text..."
                                    className="text-input"
                                />
                                <button
                                    onClick={handleGenerate}
                                    className="generate-button"
                                >
                                    Generate
                                </button>
                            </div>
                            <textarea
                                value={generatedText}
                                readOnly
                                className="preview-output"
                            />
                        </div>
                    </div>

                    {/* Color Selection */}
                    <div className="card">
                        <h2 className="card-title">Color Selection</h2>
                        <ColorSelection
                            style={style}
                            selectedColors={selectedColors}
                            setSelectedColors={setSelectedColors}
                            onColorChange={handleGenerate}
                        />
                    </div>

                    {/* Style & Formatting */}
                    <div className="card">
                        <h2 className="card-title">Style & Formatting</h2>
                        <div className="formatting-container">
                            <div>
                                <label className="color-select-label">Animation Style</label>
                                <select
                                    className="style-select"
                                    value={style}
                                    onChange={(e) => handleStyleChange(e.target.value)}
                                >
                                    <option value="hypixel">Hypixel Style</option>
                                    <option value="ghostly">Ghostly Style</option>
                                </select>
                            </div>
                            <div>
                                <label className="color-select-label">Formatting</label>
                                <div className="format-buttons">
                                    {Object.keys(MinecraftFormatting).map((format) => (
                                        <button
                                            key={format}
                                            onClick={() => handleFormatting(format)}
                                            className={`format-button ${formatting.includes(format) ? 'active' : ''}`}
                                        >
                                            {format}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnimationGeneratorStyled;