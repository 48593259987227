export const defaultHypixelColors = {
    color1: '&6', // GOLD
    color2: '&e', // YELLOW
    color3: '&f'  // WHITE
};

export const generateHypixelStyle = (word, selectedColors, formatCodes) => {
    const colors = [
        selectedColors.color1 + formatCodes,
        selectedColors.color2 + formatCodes,
        selectedColors.color3 + formatCodes
    ];
    let result = [];

    result.push(`${colors[0]}${word}`);
    result.push(`${colors[1]}${word}`);

    for (let i = 0; i < word.length; i++) {
        let frame = '';
        frame += `${colors[2]}${word.substring(0, i)}`;
        frame += `${colors[1]}${word[i]}`;
        frame += `${colors[0]}${word.substring(i + 1)}`;
        result.push(frame);
    }

    result.push(`${colors[2]}${word}`);
    result.push(`${colors[0]}${word}`);
    result.push(`${colors[1]}${word}`);
    result.push(`${colors[0]}${word}`);
    result.push(`${colors[1]}${word}`);

    return result;
};